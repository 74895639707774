import type { ParsedToken, User } from 'firebase/auth';
import { defineStore } from 'pinia';

// Use options store for better dev tools integration
export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: null as (User & { isAdmin: boolean; isEditor: boolean }) | null,
  }),

  getters: {
    getUser: (state) => state.user,
    isLoggedIn: (state) => state.user !== null,
  },

  actions: {
    setUser(user: User, claims: ParsedToken) {
      this.user = Object.assign({}, user, {
        isAdmin: claims.admin != null,
        isEditor: claims.editor != null,
      });
    },

    clear() {
      this.user = null;
    },
  },
});
